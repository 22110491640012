import { createTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { green, orange } from '@mui/material/colors';
import zIndex from '@mui/material/styles/zIndex';
 

const primaryTheme = createTheme({
    palette: {
      primary: {
        main: "#0b0b0b",        
        font: "#212121",
        halfOpacity: "rgba(33, 33, 33, 0.5)",
      },
      secondary: {
        main: "#f9a825"
      },      
      landclearing: {
        neon: "#76FF03",
        main: "#388E3C",
        zIndex: 88,
        opacity: .15
      },    
      newConstruction: {
        neon: "#f9a825",
        main: "#f9a825",
        zIndex: 89,
        opacity: .15
      },
      newRoof: {
        neon: "#651FFF",
        main: "#8E24AA",
        zIndex: 87,
        opacity: .15
      }, 
      newRoad: {
        neon: "#18FFFF",
        main: "#00BCD4",
        zIndex: 86,
        opacity: .15
      },
      newPool: {
        neon: "#00E5FF",
        main: "#1976D2",
        zIndex: 85,
        opacity: .15
      },
      misc: {
        neon: "#FF4081",
        main: "#E91E63",
        zIndex: 81,
        opacity: .15
      },      
      active: {
        main: "#FFC107",
        zIndex: 99,
        opacity: .15
      },
      inactive: {
        main: "#212121",
        zIndex: 0,
        opacity: .5,
      },
      previous: {
        main: "#546E7A",
        zIndex: 98,
        opacity: .15
      },
      mlActive: {
        main: "#F44336",
        zIndex: 99,
        opacity: .15
      },
      previousML: {
        main: "#FAFAFA",
        zIndex: 100,
        opacity: .15

      },
      white: {
        main: "#ffffff",
        font: "#ffffff"
      },
      black: {
        main: "#212121",
        font: "#212121"
      },
      red: {
        main: "#f44336",
        contrastText: "#ffffff"
      },
      green: {
        main: '#4CAF50'
      },
      lot: {
        main: "#4CAF50"
      },
      gray: {
        main: "#BDBDBD",
        contrastText: "#6c6c6c"
      },
      disabled: {
        main: "#BDBDBD"
      },
      google: {
        main: "#E34133",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      visa: {
        main: "#1A1F71",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      mastercard: {
        main: "#FF5F00",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      amex: {
        main: "#0078A8",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      discover: {
        main: "#FF5F00",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeGreen: {
        main: "#4CAF50",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeYellow: {
        main: "#FFA000",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeRed: {
        main: "#F44336",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeGray: {
        main: "#9E9E9E",
        font: '#ffffff',
        contrastText: "#ffffff",
      }      

    },
    components: {
      MuiSlider: {
        styleOverrides: {
          mark: {
            backgroundColor: '#ffffff', 
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#f9a825'
          },
        },
      },
    },
  
  });
  
const darkTheme = createTheme({
    palette: {
      primary: {
        main: orange[500],
      },
    },
  });
  


export { darkTheme, primaryTheme }