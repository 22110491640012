import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { Button, colors, Paper, FormGroup, FormControlLabel, FormControl, 
         Switch, Stack, Slider, IconButton, Popover, Typography,
        TextField} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from "react-google-charts";
import './Welcome.css';

import { BarChart } from '@mui/x-charts/BarChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";
  
import usZipCodes from '../../assets/usZipCodes.json'

import AuthInterface from '../AuthInterface/AuthInterface';

import earthImg from '../../assets/Welcome/earthImg.jpg'

// import demo2024 from '../../assets/Welcome/demo/2024-high.jpg'
import demo_egypt from '../../assets/Welcome/demo/egypt.jpg'
import demo_malaysia from '../../assets/Welcome/demo/malaysia.jpg'
import demo_nashville from '../../assets/Welcome/demo/nashville.jpg'

import timeDemo2021_01 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-01.jpg'
import timeDemo2021_02 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-02.jpg'
import timeDemo2021_03 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-03.jpg'
import timeDemo2021_04 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-04.jpg'
import timeDemo2021_05 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-05.jpg'
import timeDemo2021_06 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-06.jpg'
import timeDemo2021_07 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-07.jpg'
import timeDemo2021_08 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-08.jpg'
import timeDemo2021_09 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-09.jpg'
import timeDemo2021_10 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-10.jpg'
import timeDemo2021_11 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-11.jpg'
import timeDemo2021_12 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2021-12.jpg'
import timeDemo2022_01 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-01.jpg'
import timeDemo2022_02 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-02.jpg'
import timeDemo2022_03 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-03.jpg'
import timeDemo2022_04 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-04.jpg'
import timeDemo2022_05 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-05.jpg'
import timeDemo2022_06 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-06.jpg'
import timeDemo2022_07 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-07.jpg'
import timeDemo2022_08 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-08.jpg'
import timeDemo2022_09 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-09.jpg'
import timeDemo2022_10 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-10.jpg'
import timeDemo2022_11 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-11.jpg'
import timeDemo2022_12 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2022-12.jpg'
import timeDemo2023_01 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-01.jpg'
import timeDemo2023_02 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-02.jpg'
import timeDemo2023_03 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-03.jpg'
import timeDemo2023_04 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-04.jpg'
import timeDemo2023_05 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-05.jpg'
import timeDemo2023_06 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-06.jpg'
import timeDemo2023_07 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-07.jpg'
import timeDemo2023_08 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-08.jpg'
import timeDemo2023_09 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-09.jpg'
import timeDemo2023_10 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-10.jpg'
import timeDemo2023_11 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-11.jpg'
import timeDemo2023_12 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2023-12.jpg'
import timeDemo2024_01 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-01.jpg'
import timeDemo2024_02 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-02.jpg'
import timeDemo2024_03 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-03.jpg'
import timeDemo2024_04 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-04.jpg'
import timeDemo2024_05 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-05.jpg'
import timeDemo2024_06 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-06.jpg'
import timeDemo2024_07 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-07.jpg'
import timeDemo2024_08 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-08.jpg'
import timeDemo2024_09 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-09.jpg'
import timeDemo2024_10 from '../../assets/Welcome/timeSeriesDemo/NACEgypt/2024-10.jpg'

import { returnZipCodeLatLng } from '../../functions/zipCodes';


import { AddCircle, ArrowCircleDown, ArrowCircleUp, Biotech, Gavel, HolidayVillage, Info, Search, LockOpen, Cloud, Forum, Psychology, AutoFixHigh, ZoomIn, ZoomInMap, ViewInAr, Crop, BurstMode, RemoveRedEye, DateRange, Person, Explore, TravelExplore, Mail  } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

function Welcome(props) {

    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const theme = useTheme()
    const navigate = useNavigate()
    const timeSeriesDemoInterval = 1000
    const { section } = useParams();

    const numberOfSections = 4
    

    const backgroundImgRef = useRef(null)
    const scrollContainerRef = useRef(null)
    const [sectionSelectorMarginTop, setSectionSelectorMarginTop] = useState(0)

    const viewportWidthPxRef = useRef(window.innerWidth)
    const [viewportWidthPx, setViewportWidthPx] = useState(viewportWidthPxRef.current)
    const viewportRatioRef = useRef(window.innerWidth / window.innerHeight)
    const [viewportRatio, setViewportRatio] = useState(viewportRatioRef.current)
    const isMobileRef = useRef(viewportRatioRef.current < .75)
    const [isMobile, setIsMobile] = useState(isMobileRef.current)


    const [highAltitudeImgPercent, setHighAltitudeImgPercent] = useState(100)
    const [mediumAltitudeImgPercent, setMediumAltitudeImgPercent] = useState(100)
    const [lowAltitudeImgPercent, setLowAltitudeImgPercent] = useState(100)



    const reasonItsCheapUpdateTime = useRef(0)
    const reasonItsCheapIndexRef = useRef(0)
    const reasonItsCheapTextRef = useRef(null)
    
    const [reasonsItsCheapIndex, setReasonsItsCheapIndex] = useState(0)
    const [reasonsItsCheapNextIndex, setReasonsItsCheapNextIndex] = useState(0)
    
    const [reasonButtonWidth, setReasonButtonWidth] = useState('auto')
    const [reasonButtonTextOpacity, setReasonButtonTextOpacity] = useState(1)

    const currentSectionRef = useRef(0)
    const [currentSection, setCurrentSection] = useState(0)

    const [imgZoomFactor, setImgZoomFactor] = useState(1)

    const [priceCompareInfoPopoverAnchorEl, setPriceCompareInfoPopoverAnchorEl] = useState(null)

    const [imageDemoimageBrightness, setImageDemoImageBrightness] = useState(.7)
    const [imageDemoCoverOpacity, setImageDemoCoverOpacity] = useState(1)
    const [imageDemoCoverActive, setImageDemoCoverActive] = useState(true)

    const lastScrollTimeRef = useRef(0)
    const lastScrollPositionRef = useRef(0)

    const timeSeriesDemoPausedRef = useRef(false)
    const timeSeriesDemoIndexRef = useRef(0)
    const [timeSeriesDemoIndex, setTimeSeriesDemoIndex] = useState(0)

    const [zipCode, setZipCode] = useState('')

    

    const [priceComparisonDataState, setPriceComparisonDataState ] = useState({
        Airbus: {
            company: 'Airbus',
            satellite: 'Pléiades',
            price: 20,
            label: '$20',
            resolution: '0.3m',
            visible: true,
        },
        Planet: {
            company: 'Planet',
            satellite: 'PlanetScope',
            price: 2.5,
            label: '$2.50',
            resolution: '4.7m',
            visible: true,
        },
        LotEagle: {
            company: 'Lot Eagle',
            satellite: 'Sentinel-2',
            price: 0.2,
            label: '$0.20',
            resolution: '10m',
            visible: true,
        },
    })

    const timeSeriesDemoImages = [        
        {
            src: timeDemo2021_04,
            value: 24,
            label: '',
            date: 'Apr 2021',
        },
        {
            src: timeDemo2021_05,
            value: 25,
            label: '',
            date: 'May 2021',
        },
        {
            src: timeDemo2021_06,
            value: 26,
            label: '',
            date: 'Jun 2021',
        },
        {
            src: timeDemo2021_07,
            value: 27,
            label: '',
            date: 'Jul 2021',
        },
        {
            src: timeDemo2021_08,
            value: 28,
            label: '',
            date: 'Aug 2021',
        },
        {
            src: timeDemo2021_09,
            value: 29,
            label: '',
            date: 'Sep 2021',
        },
        {
            src: timeDemo2021_10,
            value: 30,
            label: '',
            date: 'Oct 2021',
        },
        {
            src: timeDemo2021_11,
            value: 31,
            label: '',
            date: 'Nov 2021',
        },
        {
            src: timeDemo2021_12,
            value: 32,
            label: '',
            date: 'Dec 2021',
        },
        {
            src: timeDemo2022_01,
            value: 33,
            label: '2022',
            date: 'Jan 2022',
        },
        {
            src: timeDemo2022_02,
            value: 34,
            label: '',
            date: 'Feb 2022',
        },
        {
            src: timeDemo2022_03,
            value: 35,
            label: '',
            date: 'Mar 2022',
        },
        {
            src: timeDemo2022_04,
            value: 36,
            label: '',
            date: 'Apr 2022',
        },
        {
            src: timeDemo2022_05,
            value: 37,
            label: '',
            date: 'May 2022',
        },
        {
            src: timeDemo2022_06,
            value: 38,
            label: '',
            date: 'Jun 2022',
        },
        {
            src: timeDemo2022_07,
            value: 39,
            label: '',
            date: 'Jul 2022',
        },
        {
            src: timeDemo2022_08,
            value: 40,
            label: '',
            date: 'Aug 2022',            
        },
        {
            src: timeDemo2022_09,
            value: 41,
            label: '',
            date: 'Sep 2022',
        },
        {
            src: timeDemo2022_10,
            value: 42,
            label: '',
            date: 'Oct 2022',
        },
        {
            src: timeDemo2022_11,
            value: 43,
            label: '',
            date: 'Nov 2022',
        },
        {
            src: timeDemo2022_12,
            value: 44,
            label: '',
            date: 'Dec 2022',
        },
        {
            src: timeDemo2023_01,
            value: 45,
            label: '2023',
            date: 'Jan 2023',
        },
        {
            src: timeDemo2023_02,
            value: 46,
            label: '',
            date: 'Feb 2023',
        },
        {
            src: timeDemo2023_03,
            value: 47,
            label: '',
            date: 'Mar 2023',
        },
        {
            src: timeDemo2023_04,
            value: 48,
            label: '',
            date: 'Apr 2023',
        },
        {
            src: timeDemo2023_05,
            value: 49,
            label: '',
            date: 'May 2023',
        },
        {
            src: timeDemo2023_06,
            value: 50,
            label: '',
            date: 'Jun 2023',
        },
        {
            src: timeDemo2023_07,
            value: 51,
            label: '',
            date: 'Jul 2023',
        },
        {
            src: timeDemo2023_08,
            value: 52,
            label: '',
            date: 'Aug 2023',
        },
        {
            src: timeDemo2023_09,
            value: 53,
            label: '',
            date: 'Sep 2023',
        },
        {
            src: timeDemo2023_10,
            value: 54,
            label: '',
            date: 'Oct 2023',            
        },
        {
            src: timeDemo2023_11,
            value: 55,
            label: '',
            date: 'Nov 2023',            
        },
        {
            src: timeDemo2023_12,
            value: 56,
            label: '',
            date: 'Dec 2023',
        },
        {
            src: timeDemo2024_01,
            value: 57,
            label: '2024',
            date: 'Jan 2024',
        },
        {
            src: timeDemo2024_02,
            value: 58,
            label: '',
            date: 'Feb 2024',
        },
        {
            src: timeDemo2024_03,
            value: 59,
            label: '',
            date: 'Mar 2024',
        },
        {
            src: timeDemo2024_04,
            value: 60,
            label: '',
            date: 'Apr 2024',
        },
        {
            src: timeDemo2024_05,
            value: 61,
            label: '',
            date: 'May 2024',
        },
        {
            src: timeDemo2024_06,
            value: 62,
            label: '',
            date: 'Jun 2024',
        },
        {
            src: timeDemo2024_07,
            value: 63,
            label: '',
            date: 'Jul 2024',
        },
        {
            src: timeDemo2024_08,
            value: 64,
            label: '',
            date: 'Aug 2024',
        },
        {
            src: timeDemo2024_09,
            value: 65,
            label: '',
            date: 'Sep 2024',
        },
        {
            src: timeDemo2024_10,
            value: 66,
            label: '',
            date: 'Oct 2024',
        }        
    ]


    useEffect(() => {


        const minDemoValue = Math.min.apply(Math, Object.values(timeSeriesDemoImages).map(e => e.value))
        const maxDemoValue = Math.max.apply(Math, Object.values(timeSeriesDemoImages).map(e => e.value))
        timeSeriesDemoIndexRef.current = minDemoValue
        setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)

        const interval = setInterval(() => {
            if (timeSeriesDemoPausedRef.current == false) {
                if(timeSeriesDemoIndexRef.current < maxDemoValue){
                    timeSeriesDemoIndexRef.current = timeSeriesDemoIndexRef.current + 1                   
                }else{
                    timeSeriesDemoIndexRef.current = minDemoValue            
                }
                setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)
            }
        }, timeSeriesDemoInterval)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const handleResize = () => {
                setTimeout(() => {
                    //get aspect ratio
                    const vw = window.innerWidth
                    const vh = window.innerHeight
                    const ratio = vw/vh
                    viewportRatioRef.current = ratio
                    viewportWidthPxRef.current = vw
                    isMobileRef.current = ratio < .75

                    setViewportRatio(viewportRatioRef.current)
                    setViewportWidthPx(vw)
                    setIsMobile(isMobileRef.current)


                    changeSections(currentSectionRef.current, true)
                    updateBackroundImage()
                    
                }, 500);
            }
        
        window.addEventListener('resize', handleResize)

        return () => {
            //remove the event listener
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    
    useEffect(() => {
        const div = scrollContainerRef.current;

        if(section != null){
            changeSections(parseInt(section), true)
        }

        function handleScroll(event) {
            event.preventDefault(); // Prevent the default scroll behavior

            if(event.type == "wheel"){
                //check if the user is scrolling down or up
                if (event.deltaY > 0) {
                    //scrolling down
                    changeSections(currentSectionRef.current + 1, false)
                }else{
                    //scrolling up
                    changeSections(currentSectionRef.current - 1, false)
                }
            }        
        }

        // Add the event listener with passive: false
        div.addEventListener('wheel', handleScroll, { passive: false });
        div.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            div.removeEventListener('wheel', handleScroll);
            div.removeEventListener('scroll', handleScroll);
        };

    }, [])


    function updateBackroundImage() {
        try{

            const vw = window.innerWidth
            const vh = window.innerHeight

            viewportRatioRef.current = vw / vh
            isMobileRef.current = viewportRatioRef.current < .75

            var percentScrolled = currentSectionRef.current / numberOfSections <= 1 ? currentSectionRef.current / numberOfSections : 1

            if(percentScrolled < .2){
                percentScrolled = .2
            }

            const totalPixels = isMobileRef.current ? (-vw*1.77/viewportRatioRef.current)+vh: (-vw*1.77)+vh
            backgroundImgRef.current.style.transform = `translateY(${totalPixels * percentScrolled}px)`; // Adjust the multiplier for slower or faster parallax
            backgroundImgRef.current.style.opacity = 1 - (Math.sqrt((percentScrolled - .6 < 0 ? 0:percentScrolled - .6 ) * .75 )); // Adjust the multiplier for slower or faster parallax            
        }catch(e) {
            console.error(e)
        }
    }

    const companyFormatter = (value, context) => { 
        try{
            const company = Object.values(priceComparisonDataState).find((item) => item.company == value)

            if(isMobileRef.current) {
                return context.location === 'tick' ? `${value}`: `${value} | ${company.satellite} ${company.resolution}` 
            }else{
                return context.location === 'tick' ? `${value} (${company.resolution})`: `${value} | ${company.satellite}` 

            }
        } catch (e) {
            console.error(e)
            return value
        }
    }
    const priceFormatter = (value, context) => { return `$${Math.floor(value)}` } 
    const barChartValueFormatter = (value) => { return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })).format(value) } 


    function startDemo(_start){
        if(_start) {
            setImageDemoCoverOpacity(0)
            setTimeout(() => {
                setImageDemoCoverActive(false)
                setImageDemoImageBrightness(1.1)

            }, 550)
        }else{
            setImageDemoImageBrightness(.7)
            setTimeout(() => { 

                setImageDemoCoverOpacity(1)
                setImageDemoCoverActive(true)  

            }, 550);
        }
            
    }

    function changeSections(_section, _immediate) {

        if(_section < 0){
            _section = 0
        }else if(_section > numberOfSections){
            _section = numberOfSections
        }

        const sectionHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 60;
        //prevents multiple scrolls from firing

        if(lastScrollTimeRef.current + 2000 < (new Date()).getTime() || _immediate) {

            currentSectionRef.current = _section
            try{
                setSectionSelectorMarginTop(`calc(-${100 * _section}vh + ${60 * _section}px)`)
    
                lastScrollTimeRef.current = (new Date()).getTime()    
            }catch(e){
                console.error(e)
            }

            updateBackroundImage()


            //update navigation 
            navigate(`/Welcome/${_section}`)

        }

    }  

    return (
        <div className="welcomeContent" >
            <img ref={backgroundImgRef} src={earthImg} alt="earth" className="backgroundImg" style={{width: `${viewportWidthPx}px`, height: isMobile? `${viewportWidthPx*1.77/viewportRatio}px`:`${viewportWidthPx*1.77}px`}} />            
            <div ref={scrollContainerRef} className='welcomeContentScroller'>
                <div className='contentHolder' style={{marginTop: sectionSelectorMarginTop}}>
                    <div className='section01'>                    
                        <div>
                            <h1>Satellite Powered Analytics</h1>
                            <p>12+ months ahead of Google Maps*</p>

                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{
                                    textTransform: 'none',
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                    fontSize: '1.5em',
                                    width: '100%',
                                }}
                                size={'large'}
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(1, true)}}>
                                Next
                            </Button>  
                        </div>
                    </div>

                    <div className='section03'>
                        <h1>Why pay for <br />ultra-high resolution <br />you don't need?</h1>
                        <p>
                            Lot Eagle offers 10m resolution imagery at a fraction of the cost of other providers.
                        </p>
                        <div className='navigationButtonHolder'>
                            <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(0, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText }}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(2, true)}}>
                                Next
                            </Button>                       
                        </div>
                        
                    </div>
                    {/* <div className='section04'>
                        <h1>How do our <br /> prices compare?</h1>
                        <div className='priceCompareHolder'>
                            <Paper className='barChart' elevation={10} style={{borderColor: theme.palette.primary.main, flexDirection: 'column'}}>                           
                                <h3>
                                    Price USD per km<sup>2</sup>
                                    <IconButton
                                        onClick={(event) => setPriceCompareInfoPopoverAnchorEl(event.currentTarget)}
                                        size='small'
                                        style={{marginLeft: '10px'}}
                                        aria-describedby={Boolean(priceCompareInfoPopoverAnchorEl) ? 'priceCompareInfoPopoverButton':undefined}
                                        >
                                            <Info />
                                    </IconButton>
                                    <Popover
                                        id={Boolean(priceCompareInfoPopoverAnchorEl) ? 'priceCompareInfoPopoverButton':undefined}
                                        open={Boolean(priceCompareInfoPopoverAnchorEl)}
                                        anchorEl={priceCompareInfoPopoverAnchorEl}
                                        onClose={() => setPriceCompareInfoPopoverAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        >
                                        <Typography sx={{ p: 2 }}>Comparison based on <a href='https://skywatch.com/data-pricing/' target='_blank' style={{color: theme.palette.primary.main, fontWeight: 'bold', textDecoration: 'underline'}}>SkyWatch 2024</a> pricing data.</Typography>
                                    </Popover>

                                </h3>
                                <BarChart
                                    dataset={Object.values(priceComparisonDataState).filter((item) => item.visible)}
                                    xAxis={[{ scaleType: 'band', dataKey: 'company', valueFormatter: companyFormatter }]}
                                    yAxis={[{ 
                                        scaleType: 'sqrt', 
                                        dataKey: 'price', 
                                        max: Math.max(...Object.values(priceComparisonDataState).filter((item) => item.visible).map((item) => item.price)) + 1, 
                                        valueFormatter: priceFormatter,                                    

                                    }]}
                                    barLabel={(item, context) => barChartValueFormatter(item.value)}    
                                    borderRadius={5}
                                    series={[{ 
                                        dataKey: 'price', 
                                        valueFormatter: barChartValueFormatter,
                                    }]}     
                                    
                                    colors={[theme.palette.secondary.main]}/>
                                    
                            </Paper>
                            <Paper 
                                className='priceCompareLegendHolder'
                                elevation={10}
                                >
                                    <div className='priceCompareLegend'>
                                        <h3>Data Providers</h3>
                                        <FormGroup>
                                            {
                                                Object.keys(priceComparisonDataState).map((key, index) => {

                                                    const item = priceComparisonDataState[key]
                                                    return (
                                                        <FormControlLabel 
                                                            key={index}
                                                            control={
                                                                <Switch
                                                                    checked={item.visible} 
                                                                    onChange={(event) => {
                                                                        const temp = {...priceComparisonDataState}
                                                                        temp[key].visible = event.target.checked
                                                                        setPriceComparisonDataState(temp)
                                                                    }}
                                                                    color='secondary'
                                                                    defaultChecked />
                                                                } 
                                                            label={companyFormatter(item.company, {location: 'tick'})}                                                         
                                                            />
                                                    )
                                                })
                                            }                                                                                
                                        </FormGroup>
                                    </div>
                            </Paper>
                        </div>
                        <div className='navigationButtonHolder'>
                        <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(1, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(3, true)}}>
                                Next
                            </Button>          
                        </div>
                    </div> */}

                    <div className='section06'>
                        <h1>Track changes <br /> as they happen</h1>
                        <Paper
                            className='changeDetectionHolder'
                            elevation={10}
                            >
                            <div 
                                className='changeDetectionImageHolder' 
                                onClick={() => {
                                    timeSeriesDemoPausedRef.current = !timeSeriesDemoPausedRef.current
                                }}
                                style={{cursor: 'pointer'}}>
                                <h3>{Object.values(timeSeriesDemoImages).find(e => e.value == timeSeriesDemoIndex)?.date} - Cairo, Egypt</h3>
                                {
                                    timeSeriesDemoImages.map((item, index) => {

                                        return <img 
                                                    key={`changeDetection-${index}`} 
                                                    src={item.src} 
                                                    style={{
                                                        opacity: (timeSeriesDemoIndex >= 0 ? timeSeriesDemoIndex:0) >= item.value ? 1 : 0,
                                                    }} 
                                                    className="changeDetectionImage" />
                                    })
                                }
                            </div>
                            <Paper
                                className='changeDetectionSliderHolder'
                                elevation={10}>
                                <Slider 
                                    size="small" 
                                    aria-label="Month Picker" 
                                    color="secondary" 
                                    value={timeSeriesDemoIndex} 
                                    min={Math.min.apply(Math, Object.values(timeSeriesDemoImages).map(e => e.value))}
                                    step={1}
                                    max={Math.max.apply(Math, Object.values(timeSeriesDemoImages).map(e => e.value))}
                                    marks={timeSeriesDemoImages.map((item) => { return {value: item.value, label: item.label}})}
                                    sx={() => {
                                        const returnObj = {
                                            '& .MuiSlider-mark': {
                                              height: '3px',
                                              width: '3px',
                                              marginLeft: '-.5px',
                                              borderWidth: '1px',
                                            },      
                                          }
                                          timeSeriesDemoImages.forEach((item, index) => {
                                            if(item.label != ''){
                                                returnObj[`& .MuiSlider-mark[data-index="${index}"]`] = {
                                                    height: '6px',
                                                    width: '6px',
                                                    marginLeft: '-3px',
                                                    borderWidth: '2px',
                                          
                                                  }    
                                            }
                                          })

                                        return returnObj
                                    }}
                                    onChange={(event, number) => { 
                                        timeSeriesDemoPausedRef.current = true
                                        timeSeriesDemoIndexRef.current = number
                                        setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)
                                    }}
                                    style={{margin: 0}}/>

                            </Paper>
                        </Paper>
                        <div className='navigationButtonHolder'>
                            <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(2, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(4, true)}}>
                                Next
                            </Button>    
                        </div>                        
                    </div>
                    <div className='section07'>
                        <h1>Mission Demos</h1>
                        <div className='missionDemoHolder'>
                            <Paper className='missionDemoElement' elevation={10} onClick={() => navigate("/Demo/HS43G3hc2vv8LtmvTfXY")}>
                                <h3>NAC, Cairo, Egypt</h3>
                                <img src={demo_egypt} className='missionDemoImage' />
                            </Paper>
                            <Paper className='missionDemoElement' elevation={10} onClick={() => navigate("/Demo/glJZsJO0IDoE85yLTpcn")}>
                                <h3>Song, Sarawak, Malaysia</h3>
                                <img src={demo_malaysia} className='missionDemoImage' />
                            </Paper>
                            <Paper className='missionDemoElement' elevation={10} onClick={() => navigate("/Demo/EVwt2HO5zIqK6IAIuCIF")}>
                                <h3>Nashville, TN, USA</h3>
                                <img src={demo_nashville} className='missionDemoImage' />
                            </Paper>
                        </div>
                        <div className='navigationButtonHolder'>
                            <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(3, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(5, true)}}>
                                Next
                            </Button>    
                        </div>        
                    </div>
                    {/* <div className='section08'>
                        <h1>Ready to start exploring?</h1>                        
                        <div className='section08ButtonHolder'>
                            <Paper className='section08ButtonZipHolder'>                               
                                <TextField 
                                    id="filled-basic" 
                                    label="Zip / Postal Code" 
                                    autoComplete="billing postal-code"
                                    variant="outlined" 
                                    className='section08ButtonTextInput'
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}/>
                                <Button 
                                    variant='contained'
                                    color="secondary"
                                    size='large'
                                    startIcon={<TravelExplore />}
                                    onClick={() => {
                                        navigate(`/Welcome/CreateLot/${zipCode}?step=select`)
                                    }}
                                    disabled={returnZipCodeLatLng(zipCode) == null}
                                    className='section08SubmitButton'>
                                        Submit
                                </Button>                         
                            </Paper>
                            <Button 
                                variant='text'
                                color="white"
                                size='large'
                                onClick={() => {
                                    navigate('/Welcome/CreateLot?step=select')
                                }}
                                style={{
                                    marginTop: '10px'
                                }}>
                                    Continue without a zip / postal code
                            </Button>
                            <div className='navigationButtonHolder'>
                                <IconButton 
                                    variant='contained'
                                    color='primary'
                                    style={{                                
                                        marginRight: '10px',
                                        backgroundColor: theme.palette.primary.main,
                                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    }}
                                    size='large'
                                    onClick={() => {
                                        changeSections(4, true)
                                    }}>
                                        <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                                </IconButton>                                
                                <div style={{flex: 1}}/>   
                            </div>     
                        </div>
                        
                    </div>  */}
                    <div className='section08'>
                        <h1>Ready to start exploring?</h1>                        
                        <div className='section08ButtonHolder'>                            
                            <Button 
                                variant='contained'
                                color="secondary"
                                size='large'
                                onClick={() => {
                                    navigate('/ContactUs')
                                }}
                                startIcon={<Mail />}
                                style={{
                                    width: '300px',
                                    fontSize: '1.25em',
                                }}>
                                    Contact Us
                            </Button>
                            <div className='navigationButtonHolder'>
                                <IconButton 
                                    variant='contained'
                                    color='primary'
                                    style={{                                
                                        marginRight: '10px',
                                        backgroundColor: theme.palette.primary.main,
                                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    }}
                                    size='large'
                                    onClick={() => {
                                        changeSections(4, true)
                                    }}>
                                        <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                                </IconButton>                                
                                <div style={{flex: 1}}/>   
                            </div>     
                        </div>
                        
                    </div>
                </div>
            </div>            
        </div>
    )

}


export default Welcome