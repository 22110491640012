import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';
import './App.css'
import Navbar from "./components/Navbar/Navbar";
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom'
import { CircularProgress, Collapse, Alert, IconButton } from '@mui/material';
import { primaryTheme } from './theme'
import CloseIcon from '@mui/icons-material/Close';
import { createRandomKey } from './functions/createRandomKey'

import Account from './components/Account/Account'
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import AdminMapReview from './components/AdminMapReview/AdminMapReview'
import Blog from './components/Blog/Blog'
import BlogHome from './components/BlogHome/BlogHome';
import ContactUs from './components/ContactUs/ContactUs';
import Checkout from './components/Checkout/Checkout';
import ExportImageryManager from './components/ExportImageryManager/ExportImageryManager';
import GetStarted from './components/GetStarted/GetStarted';
import GifCreation from './components/GifCreation/GifCreation';
import GifReview from './components/GifReview/GifReview';
import Home from './components/Home/Home';
import Invite from './components/Invite/Invite';
import InviteAdmin from './components/InviteAdmin/InviteAdmin';
import MapView from './components/MapView/MapView';
import ViewLots from './components/ViewLots/ViewLots';
import Organization from './components/Organization/Organization';
import Legal from './components/Legal/Legal';
import Pricing from './components/Pricing/Pricing';
import AdminHR from './components/AdminHR/AdminHR';
import AdminTasking from './components/AdminTasking/AdminTasking';
import ViewLot from './components/ViewLot/ViewLot';
import Welcome from './components/Welcome/Welcome';
import RedirectOnAuth from './components/RedirectOnAuth/RedirectOnAuth';

import { setUserDataFirestore } from './functions/setUserDataFirestore'
import { toEasternTime } from './functions/toEasternTime'

import settings from './settings.json'
import { fetchFromAPI } from './functions/fetchFromAPI';

firebase.initializeApp(settings.firebaseConfig)
  
const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

function App() {

    const userRef = useRef(null)
    const listenForAdminHRRef = useRef(null)
    const [user, setUser] = useState(null)
    const userMetaDataRef = useRef(null)
    const [userMetaData, setuserMetaData] = useState(null)
    const [authLoaded, setAuthLoaded] = useState(false)

    
    const [userOrganizations, setUserOrganizations] = useState({})
    
    const [userOrgsLoaded, setUserOrgsLoaded] = useState(false)


    const [selectedOrganization, setSelectedOrganization] = useState(null)

    const [adminHRObject, setAdminHRObject] = useState(null)

    const alertQueue = useRef([])
    const [refresh, setRefresh] = useState(0)

    const [generalAppConfig, setGeneralAppConfig] = useState({})

    const devMode = settings?.devMode == true /*settings?.admins.includes(user?.uid) && */
    const adminMode = settings?.admins.includes(user?.uid);

    const sharedStateRef = useRef({})
    const [sharedState, setSharedState] = useState({})

    let userMetaDataListener = null

    //pricing schedule
    useEffect(() => {

        getGeneralAppConfig()   

    }, [])

    //keeps track of auth state and prevents multiple calls to setUserDataFirestore
    useEffect(() => {       
        
        setInterval(() => {
            if(alertQueue.current[0]?.visible && alertQueue.current[0]?.closeTime < (toEasternTime(new Date())).getTime()){
                closeActiveAlert()
            }            
        }, 500)

        const unsubscribe = auth.onAuthStateChanged(async function(user) {
            
            
            if(userMetaDataListener){
                userMetaDataListener()
                userMetaDataListener = null
            }
            
            // Check if authentication has loaded
            if (user != null) { 
                userRef.current = user
                setUser(userRef.current)
                setUserOrgsLoaded(false)
                getAdminHRObject()
                // User is signed in, you can access user information here
                try{
                    await setUserDataFirestore(userRef.current)                    
                }catch(error){
                    console.error(error)
                }
                
                try{
                    // listen for changes to user metadata
                    userMetaDataListener =  firestore
                                            .collection('UserData').doc(userRef.current.uid)
                                            .onSnapshot((doc) => {
                                                if(doc.exists){
                                                    userMetaDataRef.current = doc.data()
                                                    setuserMetaData(doc.data())
                                                    getUserOrganizations(userRef.current)
                    
                                                }else{
                                                    userMetaDataRef.current = null
                                                    setuserMetaData(null)
                                                }
                                            })
                                            
                }catch(error){
                    console.error(error)
                    try{
                        getUserOrganizations(userRef.current)
                    }catch(error){
                        console.error(error)
                    }
                }
            } else {
                // User is signed out
                userRef.current = null
                listenForAdminHRRef.current = null
                setUser(null)
                userMetaDataRef.current = null
                setuserMetaData(null)
                setUserOrganizations({})
                setUserOrgsLoaded(true)
                setSelectedOrganization(null)
                setAdminHRObject(null)
                
                //clear the shared state
                sharedStateRef.current = {}
                setSharedState({})


            }
            setAuthLoaded(true)

        })
        return () => unsubscribe();
        
    }, [])

    useEffect(() => {
        if(userRef.current != null){
            //update user info in the organization 
            //Organizations/{orgId}/Users/{userId}
            if( selectedOrganization != null && 
                selectedOrganization in userOrganizations && 
                userOrganizations[selectedOrganization] != null && 
                (userOrganizations[selectedOrganization].analysts[userRef.current.uid] === true || userOrganizations[selectedOrganization].superAdmins[userRef.current.uid] === true)){
                const userOrgDocRef = firestore.collection('Organizations').doc(selectedOrganization).collection('Users').doc(userRef.current.uid)
                const userInfoObj = {
                    displayName: userRef.current.displayName,
                    email: userRef.current.email,
                    photoURL: userRef.current.photoURL,
                    uid: userRef.current.uid,
                    lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                }

                userOrgDocRef
                .set(userInfoObj, { merge: true })
                .then(() => {

                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });


                //cache the user's selected organization locally so when the user logs in again, the organization is already selected
                localStorage.setItem(`${userRef.current.uid}-selectedOrganization`, selectedOrganization)

                //if the organization paymentSettings.stripeCustomerId is not set and the user is a superAdmins then hit /organization/initStripeCustomer
                if(userOrganizations[selectedOrganization]?.paymentSettings?.stripeCustomerId == null && userOrganizations?.[selectedOrganization]?.['superAdmins']?.[userRef.current.uid] === true){
                    fetchFromAPI('stripe', 'organization/initStripeCustomer', {
                        method: 'POST',
                        body: {
                            organizationId: selectedOrganization
                        }
                    })
                    .then((response) => {                        
                        getUserOrganizations(userRef.current)
                    
                    })
                    .catch((error) => {
                        console.error(error)
                        console.error("error", "Error initializing stripe customer")
                    })
                }

            }
        }

    }, [selectedOrganization]);

    function getAdminHRObject(){
        if(userRef.current != null){
            try{
                firestore.collection('Admin').doc('InternalHR').onSnapshot((docSnapshot) => {
                    if(docSnapshot.exists){
                        const adminHRObject = docSnapshot.data()
                        if(userRef.current != null && (adminHRObject?.analysts?.[userRef.current?.uid] == true || adminHRObject?.analystManagers?.[userRef.current?.uid] == true)){
                            setAdminHRObject(adminHRObject)
                            const userHRRef = firestore.collection('Admin').doc("InternalHR").collection('Users').doc(userRef.current.uid)
                            userHRRef.set({
                                displayName: userRef.current.displayName,
                                email: userRef.current.email,
                                photoURL: userRef.current.photoURL,
                                uid: userRef.current.uid,
                                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                            }, { merge: true })
                            .then(() => {

                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });
                        }
                    }else{
                        setAdminHRObject(null)
                    }
                })                        
            }catch(error){
                console.error(error)
                setAdminHRObject(null)
            }
        }else{
            setAdminHRObject(null)
        }


        
    }

    function getUserOrganizations(){

        try{
            
            if(userRef.current != null){
                //query the Organization collection where the user is an analyst
                //analyst is a key in the organization document that contains a map of analysts
                //if the user's uid is in the map and the value is set to true, then the user is an analyst for that organization
                var query = firestore.collection('Organizations').where(`analysts.${userRef.current.uid}`, '==', true).get()
                
                const localAdminMode = settings?.admins.includes(userRef.current?.uid)                

                if(localAdminMode){
                    query = firestore.collection('Organizations').get()
                }

                try{

                    query
                    .then((querySnapshot) => {
            
                        var tempObj = {}
                        querySnapshot.forEach((doc) => {
                            tempObj[doc.id] = doc.data();
                        });

                        setUserOrganizations(tempObj)
                        
            
                        if(selectedOrganization == null || !(selectedOrganization in tempObj)){
                            //check if selectedOrganization is cached in local storage
                            const cachedSelectedOrganization = localStorage.getItem(`${userRef.current.uid}-selectedOrganization`)

                            if(cachedSelectedOrganization != null && (cachedSelectedOrganization in tempObj) && ((userMetaDataRef.current?.organizations ?? []).find((item) => item?.id == cachedSelectedOrganization)?.status == "active")){
                                setSelectedOrganization(cachedSelectedOrganization)
                            }else{

                                //get all the active organizations from the userMetaDataRef.current
                                const activeOrgs = (userMetaDataRef.current?.organizations ?? []).filter((item) => item?.status == "active")
                                var keyFound = false
                                //iterate thhough all the tempObj keys and find the first active organization
                                Object.keys(tempObj).forEach((key) => {
                                    if(activeOrgs.find((item) => item?.id == key && keyFound == false)){
                                        setSelectedOrganization(key)
                                        keyFound = true                                    
                                    }
                                })

                                if(!keyFound){
                                    setSelectedOrganization(null)
                                }
                            }
                        }
            
                    })
                    .catch((error) => {
                        console.error("Error getting documents: ", error);
                    })
                    .finally(() => {
                        setUserOrgsLoaded(true)
                    });
                }catch(error){
                    console.error(error)
                }    
            }else{
                setUserOrganizations({})
                setUserOrgsLoaded(true)
            }
        }catch(error){
            console.error(error)
        }

    }

    function getGeneralAppConfig(){

        //get pricing from /AppConfig/GenearalApp doc
        firestore.collection("AppConfig").doc("GeneralApp").get()
        .then((doc) => {
            if(doc.exists){

                //foreach item in StripePricingSchedule
                //filter out items that are not display == true
                var docData = doc.data() ?? {}
                var pricingSchedule = docData?.StripePricingSchedule ?? {}
                var tempPricingSchedule = {}  
                const stripeDisplayKey = devMode == true ? "displayTest":"display"

                
                Object.keys(pricingSchedule).forEach((key) => {
                    if(pricingSchedule[key]?.[stripeDisplayKey] == true){
                        tempPricingSchedule[key] = pricingSchedule[key]
                    }
                })

                docData.StripePricingSchedule = tempPricingSchedule

                setGeneralAppConfig(docData ?? {})
            }else{
                setGeneralAppConfig({})
            }
        })

    }

    //keeps track of alertOpen state and closes alert after 5 seconds but deletes the timeout if alertOpen is set to true again
    function createAlert(severity, message, timeout = 2000){

        const alertObj = {
            severity: severity,
            message: message,
            visible: true,
            key: createRandomKey(),
            closeTime: null,
        }

        alertQueue.current = [...alertQueue.current, alertObj]
        setRefresh(createRandomKey())

        //if the alertQueue is empty, set the timeout to close the alert
        if(alertQueue.current.length == 1){
            alertQueue.current[0].closeTime = (toEasternTime(new Date())).getTime() + timeout
        }
        
        
    }

    function closeActiveAlert() {
        alertQueue.current[0].visible = false
        setRefresh(createRandomKey())
        setTimeout(() => {
            const tempArray = [...alertQueue.current]
            tempArray.shift()
            alertQueue.current = tempArray
            //if the alertQueue is not empty, set the timeout to close the alert
            if(alertQueue.current.length > 0){
                alertQueue.current[0].closeTime = (toEasternTime(new Date())).getTime() + 5000
            }
            setRefresh(createRandomKey())            
        }, 500);
    }

    const loggedIn = user != null 
    
    const sharedStateObj = {
        'sharedStateRef': sharedStateRef,
        'sharedState': sharedState,
        'setSharedState': setSharedState
    }

    
    if(!authLoaded || !userOrgsLoaded) {
        return (
            <ThemeProvider theme={primaryTheme}>
            <style id="dynamicCSS"></style>
            <Router>
                <div className="App">
                    <Navbar auth={auth} authLoaded={authLoaded} sharedStateObj={sharedStateObj} user={user} organizationObj={{}} devMode={devMode} adminMode={adminMode} createAlert={createAlert}/>
                    <div className='AppContent'>
                        <div className='loadingScreen'>
                            <CircularProgress color="secondary" />
                        </div>
                    </div>
                </div>
            </Router>
            </ThemeProvider>
        )
    }

    

    const organizationObj = {
        "setSelectedOrganization": setSelectedOrganization,
        "selectedOrganization": selectedOrganization,
        "userOrganizations": userOrganizations,
        "orgObj": userOrganizations[selectedOrganization],
        "orgObjLoaded": userOrganizations[selectedOrganization] != null,
        "getUserOrganizations": getUserOrganizations,
        "organizationSet": (typeof selectedOrganization == 'string' && userOrganizations[selectedOrganization] != null) || !userOrgsLoaded,
        "userIsOrgSuperAdmin": userOrganizations[selectedOrganization]?.superAdmins?.[user?.uid] === true
    }

    const adminHRObj = {
        'adminHRObject': adminHRObject,
        'mapTaskingMode': (adminHRObject != null && (adminHRObject?.analysts?.[user?.uid] == true) || (adminHRObject?.analystManagers?.[user?.uid] == true)),
        'mapReviewManagerMode': (adminHRObject != null && adminHRObject?.analystManagers?.[user?.uid] == true),
    }


    return (
        <ThemeProvider theme={primaryTheme}>
        <style id="dynamicCSS"></style>
        <Router>
            <div className="App">
                <Navbar auth={auth} authLoaded={authLoaded} sharedStateObj={sharedStateObj} user={user} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} createAlert={createAlert}/>
                <div className='AppContent'>
                    <Routes>
                        <Route exact path="/" element={<Welcome auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        <Route exact path="/RedirectOnAuth/:profile/:route" element={<RedirectOnAuth auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} />}></Route>
                        
                        {/* <Route exact path="/" element={<Home auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route> */}
                        <Route exact path="/Account" element={loggedIn ? <Account auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>
                        <Route exact path="/TaskingHR" element={adminHRObj.mapTaskingMode || adminMode ? <AdminHR auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>  : <Navigate to="/"/> }></Route>
                        <Route exact path="/Tasking" element={adminHRObj.mapTaskingMode || adminMode ? <AdminTasking auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>  : <Navigate to="/"/> }></Route>
                        
                        <Route exact path="/AdminDashboard" element={adminMode ? <AdminDashboard auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} reviewMode="reviewMode"/> : <Navigate to="/"/> }></Route>
                        
                        <Route exact path="/MapReview" element={adminMode ? <AdminMapReview auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} reviewMode="reviewMode"/> : <Navigate to="/"/> }></Route>
                        <Route exact path="/MapTasking/:squareId/:taskId" element={adminHRObj.mapTaskingMode || adminMode ? <AdminMapReview auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} reviewMode="taskMode"/> : <Navigate to="/"/> }></Route>
                        <Route exact path="/MapValidation/:squareId/:taskId/:reviewerId" element={adminHRObj.mapReviewManagerMode ? <AdminMapReview auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} reviewMode="validateMode"/> : <Navigate to="/"/> }></Route>
                        
                        <Route exact path="/ContactUs" element={<ContactUs auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                    
                        <Route exact path="/Invite/:orgId/:password/:email" element={<Invite auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        <Route exact path="/Invite_Admin/:password/:email" element={<InviteAdmin auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode} pageId={"AdminHR"}/>}></Route>
                        
                        <Route exact path="/Organization" element={(loggedIn && organizationObj.organizationSet) ? <Organization auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>
                        <Route exact path="/Organization/:tab" element={(loggedIn && organizationObj.organizationSet) ? <Organization auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>
                       
                        <Route exact path="/Organization/CreateLot" element={(loggedIn && organizationObj.organizationSet && organizationObj.userIsOrgSuperAdmin) ? <Checkout mode="createLot" auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>:<Navigate to="/"/> }></Route>                        
                        <Route exact path="/Organization/CreateLot/:zipCode" element={(loggedIn && organizationObj.organizationSet && organizationObj.userIsOrgSuperAdmin) ? <Checkout mode="createLot" auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>:<Navigate to="/"/> }></Route>                        
                        
                        <Route exact path="/Organization/Lots" element={(loggedIn && organizationObj.organizationSet) ? <ViewLots auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                        
                        <Route exact path="/Organization/Lots/:lotId" element={(loggedIn && organizationObj.organizationSet) ? <ViewLot auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                         
                        {/* <Route exact path="/Organization/Lots/:lotId/AddSubscription" element={(loggedIn && organizationObj.organizationSet && organizationObj.userIsOrgSuperAdmin) ? <Checkout mode="addSubscription" auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>:<Navigate to="/"/> }></Route>                                                 */}
                        <Route exact path="/Organization/Lots/:lotId/:tab" element={(loggedIn && organizationObj.organizationSet) ? <ViewLot auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                         
                        
                        <Route exact path="/Organization/MapView" element={(loggedIn && organizationObj.organizationSet) ? <MapView demoMode={false} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>
                        <Route exact path="/Organization/MapView/:lotId" element={(loggedIn && organizationObj.organizationSet) ? <MapView demoMode={false} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                        
                        <Route exact path="/Organization/MapView/:lotId/:dateValue" element={(loggedIn && organizationObj.organizationSet) ? <MapView demoMode={false} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                        
                        <Route exact path="/Organization/MapView/:lotId/:dateValue/:analyticId" element={(loggedIn && organizationObj.organizationSet) ? <MapView demoMode={false} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>                        
                        
                        <Route exact path="/Organization/ImageryManager" element={(loggedIn && organizationObj.organizationSet && adminMode) ? <ExportImageryManager auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/> : <Navigate to="/"/> }></Route>

                        <Route exact path="/Demo" element={<MapView demoMode={true} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>                        
                        <Route exact path="/Demo/:lotId" element={<MapView demoMode={true} auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>                        


                        <Route exact path="/Welcome" element={<Welcome auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        <Route exact path="/Welcome/CreateLot" element={<Checkout mode="welcome" auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        <Route exact path="/Welcome/CreateLot/:zipCode" element={<Checkout mode="welcome" auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        <Route exact path="/Welcome/:section" element={<Welcome auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        

                        <Route exact path="/TermsOfService" element={<Legal auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route>
                        {/* <Route exact path="/Pricing" element={<Pricing auth={auth} sharedStateObj={sharedStateObj} user={user} userMetaData={userMetaData} loggedIn={loggedIn} generalAppConfig={generalAppConfig} createAlert={createAlert} organizationObj={organizationObj} adminHRObj={adminHRObj} devMode={devMode} adminMode={adminMode}/>}></Route> */}

                        <Route path="*" element={<Navigate to="/"/>}></Route>
                    </Routes>

                    <div className='appAlertHolder'>
                        <Collapse 
                            in={alertQueue.current[0]?.visible}
                            className='appAlert'>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            closeActiveAlert();
                                        }}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                elevation={3}
                                severity={alertQueue.current[0]?.severity}
                                >
                                {alertQueue.current[0]?.message}
                            </Alert>
                        </Collapse>
                    </div>
                </div>
            </div>
        </Router>
        </ThemeProvider>


    )

}


export default App;
